import React, { useEffect, useRef, useState } from "react";
import { useSpring, animated, config } from "@react-spring/web";
import Host from "../host/Host";
import { DummieData } from "../helpers/DummieData";
import ContentLoader from "react-content-loader";
import "../styles/DropStyle.css";
import "../assets/fonts/Nunito-Regular.ttf";
import "../assets/fonts/Nunito-SemiBold.ttf";
import "../assets/fonts/NotoSerifTC-Regular.otf";
import {useNavigate} from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Drops = () => {
    
    const [data, setData] = useState([]);
    const [current_drop, setCurrentDrop] = useState(-1);

    const offset = useRef(0);
    const bottom_ref = useRef(false);
    const bottom_reach = useRef(false);
    
    useEffect(() => {
        fetch(`${Host.host}api/v1/vision/dropalineWeb/${offset.current}`)
        .then((response) => response.json())
        .then((responseJson) => {
            setData(responseJson.data);
        })
    }, []);

    useEffect(() => {
        const handleScroll = (event) => {
            let height = bottom_ref.current.getBoundingClientRect().bottom - 600;
            if (height <= 700 && !bottom_reach.current) {
                bottom_reach.current = true;
                loadMore();
            }
        }
    
        window.addEventListener("scroll", handleScroll, true);
    
        return (() => {
          window.removeEventListener("scroll", handleScroll, true);
        })
    }, []);

    const loadMore = () => {
        offset.current += 35;
        try {
            fetch(`${Host.host}api/v1/vision/dropalineWeb/${offset.current}`)
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.data.length > 0) {
                    setData(old => [...old, ...responseJson.data]);
                    setTimeout(() => {
                        bottom_reach.current = false;
                    }, 500);
                }
            })
        }catch (error) {
            console.log("eroroor", error);
        }
    }

    const pickLine = () => {
        let index = Math.floor(Math.random() * data.length);
        if (index == current_drop) {
            pickLine();
        }
        setCurrentDrop(index);
    }

    return (
        <div style = {{minHeight: "100%", paddingTop: "20px", width: "130px", position: "relative"}}> 

            <div className = "row g-0" style = {{width: "100%", height: "100%", position: "absolute"}}>

                <div className = "col">
            
                    <div style = {{height: "100%", width: "100%", justifyContent: "center", flexGrow: "1", display: "flex", position: "relative"}}>
                        <div style = {{
                            width: "3px",
                            height: "100%",
                            backgroundImage: `url(${require("../assets/vertical_point.png")})`,  
                            backgroundSize: "contain",
                            backgroundRepeat: "repeat",
                            backgroundPosition: "center",
                            position: "fixed"
                        }}>

                        </div>
                    
                        <div style = {{display: "flex", justifyContent: "center", position: "fixed", margin: "auto", flexDirection: "column", backgroundColor: "black"}}>
                            <div onClick = {pickLine} style = {{cursor: "default", width: "35px", height: "120px", alignItems: "center",backgroundColor: "gray", display: "flex", justifyContent: "space-around", flexDirection: "column", borderRadius: "100px"}}>
                                <img src = {require("../assets/shuffle.png")} style = {{width: "20px", height: "20px", objectFit: "contain", transform: "rotate(90deg)"}}></img>
                                <span style = {{writingMode: "vertical-rl", color: "white"}}>
                                    Pick a line
                                </span>
                            </div>
                            <div style = {{height: "80px", width: "35px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <span style = {{writingMode: "vertical-rl", color: "white", fontSize: ".8em"}}>
                                    Drop a line
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col" style = {{position: "relative"}} ref = {bottom_ref}>
                    {
                        (data.length <= 0) ? 
                        DummieData.getDummieArray().map(() => {
                            return (
                                <div
                                    className = "drop-container-inside"
                                >
                                    <ContentLoader
                                        animated = {false}              
                                        width = {"55%"}
                                        style = {{
                                            marginBottom: "10px",
                                            borderRadius: "100px",
                                        }}
                                        height = {"200px"}
                                        backgroundColor = "#1A1A1D"
                                        foregroundColor = "#1A1A1D"
                                    >
                                        <rect width="100%" height="100%" /> 
                                    </ContentLoader>
                                </div>
                            )
                        })
                        :
                        data?.map((item, index) => {
                            return (
                                <div
                                    onClick = {() => setCurrentDrop(index)}
                                    className = "drop-container-inside"
                                >
                                    <Drop 
                                        item = {item}
                                        index = {index}
                                        current_drop = {current_drop}
                                    >
                                    </Drop>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
         
        </div>
   
    )
}

const Drop = ({item, index, current_drop}) => {

    const drop_ref = useRef();

    const navigate = useNavigate();

    useEffect(() => {
        if (current_drop == index) {
            drop_ref?.current.scrollIntoView({ behavior: 'smooth', block: 'center'});
            expandDrop();
            return;
        }
        if (props.width.animation.to === "500%") {
            props.width.start({from: {width: "500%"}, to: {width: "100%"}});
            return;
        }
    }, [current_drop]);

    const props = useSpring({
        height: "220px",
        width: "100%",
        right: "0px",
        borderRadius: "15px",
    });

    const props2 = useSpring({
        opacity: (current_drop == index) ? 0 : 1,
        config: {
            duration: 100,
        }
    })

    const expandDrop = () => {
        if (props.width.animation.to === "500%") {
            navigate(`/aboutvision/${item.vision_id}`);
        }
        props.width.start({from: {width: "100%"}, to: {width: "500%"}});
        props2.opacity.start({from: {opacity: 1}, to: {opacity: 0}});
    }

    return (
        <div 
            ref = {drop_ref}
            style = {{
                position: "relative",
                height: "200px", 
                width: "55%",
                marginBottom: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <animated.div 
                style = {{
                    position: "absolute", 
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    paddingBottom: "50px",
                    zIndex: "1000",
                    ...props
                }}
                onClick = {expandDrop}
            >
                <LazyLoadImage
                    src = {`https://cdn.dl.media/media${item.poster}`}
                    style = {{
                        width: "100%", height: "100%", objectFit: "cover", position: "absolute", borderRadius: "20px"
                    }}
                />
                <animated.div className = "person-container" style = {props2}>
                    <p style = {{writingMode: "vertical-lr", color: "white", margin: "0px", letterSpacing: "5px", fontSize: ".6rem", cursor: "default"}}>
                        {item.person}
                    </p>
                </animated.div>
            </animated.div>

        </div>
    )
}


export default Drops;