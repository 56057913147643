import React, { useEffect, useState } from "react";
import Voting from "./Voting";
import Publications from "./Publications";
import Host from "../host/Host";
import "../styles/CarouselPublicationsStyle.css";
import SectionTitle from "./SectionTitle";
import Loader from "./Loader";
import { DummieData } from "../helpers/DummieData";

const CarouselPublications = (props) => {

    const [data, setData] = useState([]);
    
    useEffect(() => {
        fetchData();
    }, []);
    
    const fetchData = () => {
        fetch(`${Host.host}api/v1/vision/web/${props.offset}?take=20`, {
            headers: {
                "Accept": "application/json",
            "Content-Type": "application/json",
            },
            method: 'GET',
            mode: 'cors',
        })
        .then((response) => response.json())
        .then((response) => {
            setData(response.data);
        });
    }

    return (
        <div className = "container-fluid">

            <div className = "d-none d-md-none d-xl-flex row">
                {
                    (data.length <= 0) ? DummieData.getDummieArray().map((item, index) => {
                        return (    
                            <div className = "col-lg-6" style = {{marginBottom: "30px"}}>
                                <Loader key = {index}></Loader>
                            </div>
                        )
                    }) :
                        data?.map((item, index) => {
                        return (
                            <div className = "col-lg-6" style = {{padding: "0px"}}>
                                <Publications
                                    item = {item}
                                    href = {`/aboutvision/${item.id}`}
                                >
                                </Publications>
                            </div>
                        )
                    })
                }
            </div>
            
            {/* responsive */}
            <div className = "d-xl-none row">
                {
                    (data.length <= 0) ? DummieData.getDummieArray().map((item, index) => {
                        return (
                            <div className = "col-lg-6" style = {{marginBottom: "30px"}}>
                                <Loader key = {index}></Loader>
                            </div>
                        )
                    }) :
                        data?.map((item, index) => {
                        return (
                            <div className = "col-lg-6" style = {{padding: "0px"}}>
                                <Publications
                                    key = {index}
                                    item = {item}
                                    href = {`/aboutvision/${item.id}`}
                                >
                                </Publications>
                            </div>
                        )
                    })
                }
            </div>

        </div>  
    )

}

export default CarouselPublications;
