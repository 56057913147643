import React, { useEffect, useState, useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import Host from "../host/Host";
import { DummieData } from "../helpers/DummieData";
import Publications from "./Publications";
import {Oval} from 'react-loader-spinner';
import "../styles/Search.css";

const Search = (props) => {
    
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
    const [search, setSearch] = useState("");
    const [searching, setSearching] = useState(false);

    const input_ref = useRef();

    const [data, setData] = useState([]);
    
    const fetchData = () => {
        fetch(`${Host.host}api/v1/vision/searchWeb/${search}`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            method: 'GET',
            mode: 'cors',
        })
        .then((response) => response.json())
        .then((response) => {
            setSearching(false);
            setData(response.data);
        });
    }

    const handleModal = () => {
        setShow(!show);
    }
    
    const searchPost = () => {
        setData([]);
        setSearching(true);
        fetchData();
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            searchPost();
        }
    }

    const canDarkMode = () => {
        let image = require("../assets/search.png");
        if (props.dark) {
            if (props.darkmode) {
                return image;
            }
            image = require("../assets/light_search.png");
            return image;
        }
        return image;
    }

    return (
        <>
        <button
            onClick = {handleModal}
            style = {{display: "flex", alignItems: "center"}}
        >
            <img src = {`${canDarkMode()}`} style = {{objectFit: "contain", width: "20px", height: "20px", marginRight: "5px"}}></img>
        </button>
        <Modal 
            show={show} 
            fullscreen={fullscreen}
            onHide={() => setShow(false)}
        >
            <div style = {{width: "100%", height: "5px", display: "flex", padding: "20px", alignItems: "center", justifyContent: "flex-end", backgroundColor: "rgba(1,1,1,.8)"}}>
                <div style = {{width: "100%", height: "100%",display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                    <button onClick={handleModal} style = {{paddingRight: "7px"}} className="cursor-pointer">
                        <p className="search">X</p>
                    </button>
                </div>
            </div>
            <div style = {{width: "100%", height: "100px", display: "flex", padding: "20px", alignItems: "center", backgroundColor: "rgba(1,1,1,.8)"}}>
                <p className="search">Search</p>
                <div style = {{display: "flex", flex: "1", alignItems: "center"}}>
                    <input
                        style = {{width: "100%", paddingLeft: "10px", paddingRight: "10px"}}                        
                        type="text" 
                        placeholder = "Search"
                        value={search}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setSearch(e.target.value)}
                        autoFocus
                    />
                    <button style = {{display: "flex", alignItems: "center", justifyContent: "flex-end"}} onClick={searchPost}>
                        <img src = {require("../assets/search.png")} style = {{objectFit: "contain", width: "20px", height: "20px", margin: "0px"}}></img>
                    </button>
                </div>
            </div>
            <div style = {{width: "100%", height: "10px", paddingLeft: "20px", paddingRight: "20px", display: "flex", alignItems: "center", backgroundColor: "rgba(1,1,1,.8"}}>
                <div className=""
                    style = {{
                        width: "100%",
                        backgroundImage: `url(${require("../assets/horizontal_points.png")})`,
                        backgroundSize: "contain",
                        height: "5px",
                        backgroundRepeat: "repeat"
                    }}
                >
                </div>
            </div>
            <div style = {{paddingTop: "30px", width: "100%", display: "flex", flex: "1", paddingLeft: "20px", paddingRight: "20px", backgroundColor: "rgba(1,1,1,.8)"}}>
                {
                    (searching) ?
                <div style = {{width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Oval
                        height="40"
                        width="40"
                        color="white"
                        secondaryColor = "gray"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                </div> : null }
                <div className = "row">
                    {
                        data?.map((item, index) => {
                            return (
                                <div className = "col-lg-6" style = {{padding: "0px"}}>
                                    <Publications
                                        item = {item}
                                        href = {`/aboutvision/${item.id}`}
                                    >
                                    </Publications>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </Modal>
        </>

    )
}

export default Search;
